import { getLocalData, setLocalData } from './storage.service'

const _store = {
    state: {
        accessToken: getLocalData('accessToken', ''),
        refreshToken: getLocalData('refreshToken', ''),
        user: getLocalData('user', {}),
        error: '',
    },
    subscribers: []
}

export const setUserData = user => {
    const updatedUser = user

    _store.state = {
        ..._store.state,
        user: updatedUser
    }
    setLocalData('user', updatedUser)
    _broadcast()
}


export const getUserData = () => getLocalData('user') || _store.state.user || {}

export const setAccessToken = accessToken => {
    _store.state = {
        ..._store.state,
        accessToken,
    }
    setLocalData('accessToken', accessToken)
}

export const setRefreshToken = refreshToken => {
    _store.state = {
        ..._store.state,
        refreshToken,
    }
    setLocalData('refreshToken', refreshToken)
}

export const getAccessToken = () => getLocalData('accessToken') || _store.state.accessToken || ''

export const getRefreshToken = () => getLocalData('refreshToken') || _store.state.refreshToken || ''

const _broadcast = () => {
    setTimeout(() => {
        _store.subscribers.forEach(subscriber => {
            subscriber(_store.state)
        })
    }, 0)
}

export const isAuthenticated = () => {
    const { state } = _store
    return !!state.accessToken
}

export const logout = () => {
    setAccessToken('')
    setRefreshToken('')
    setUserData({})
}