import React, { useState } from 'react';
import { Layout } from 'antd';
import { Routes, Route } from "react-router-dom";
import routes from '../routes';
import AppHeader from './Header';
import AppSidebar from './Sidebar';

const { Content } = Layout;

const MainLayout = () => {
    const [collapsed, setCollapsed] = useState(false);
    const routeComponents = routes;
    const displaySidebar = false;
    return (
        <Layout>
            <AppSidebar collapsed={collapsed} displaySidebar={displaySidebar} />
            <Layout className="site-layout">
                <AppHeader collapsed={collapsed} displaySidebar={displaySidebar} setCollapsed={setCollapsed} />
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        // height: '100vh',
                    }}
                >
                    <RenderContent routes={routeComponents} />
                </Content>
            </Layout>
        </Layout>
    );
}

const RenderContent = (props) => {
    const { routes } = props
    return (
        <Routes>
            {
                routes.length > 0 && routes.map(({ path, element, exact }, index) => {
                    return <Route exact={exact} path={path} element={element} key={index} />
                })
            }
        </Routes>
    )
}

export default MainLayout