import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/auth.service';

const LogoutContainer = () => {
    const navigate = useNavigate()
    useEffect(() => {
        logout()
        navigate('/login')
    })

    return (
        <div className='logout'>
            Logging out...
        </div>
    )
}

export default LogoutContainer