import React from 'react';
import { Layout, Menu } from 'antd';

const { Sider } = Layout

const AppSidebar = (props) => {
    const { collapsed, displaySidebar } = props
    return (
        <>
            {
                displaySidebar && <Sider trigger={null} collapsible collapsed={collapsed}>
                    <div className="logo" />
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        items={[
                            {
                                key: '1',
                                // icon: <UserOutlined />,
                                label: 'nav 1',
                            },
                            {
                                key: '2',
                                // icon: <VideoCameraOutlined />,
                                label: 'nav 2',
                            },
                            {
                                key: '3',
                                // icon: <UploadOutlined />,
                                label: 'nav 3',
                            },
                        ]}
                    />
                </Sider>
            }
        </>

    )

}

export default AppSidebar
