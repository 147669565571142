import React from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { Layout, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import logo from '../statics/assets/images/logo_retina.png'
import { getLocalData } from '../services/storage.service';

const { Header } = Layout

const AppHeader = (props) => {
    const user = getLocalData('user')
    const { collapsed, displaySidebar, setCollapsed } = props

    return (
        <Header
            className="site-layout-background"
            style={{ padding: 0 }}
        >
            {displaySidebar && React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
            })}
            <ul className='nav-left'>
                <li>
                    <Link to='/' className='logo'><img src={logo} alt='logo' /></Link>
                </li>
            </ul>
            <ul className='nav-right'>
                <li className='user-info'>
                    <Avatar size={40} src={user?.picture} />
                    <Dropdown overlay={menu}>
                        <a onClick={e => e.preventDefault()}>
                            <span className='username'>{user?.name}</span> <br />
                            <span className='email'>{user?.email}</span>
                        </a>
                    </Dropdown>
                </li>
            </ul>
        </Header>
    )
}

const menu = (
    <Menu
        items={[
            {
                label: (
                    <Link to='/'>Profile </Link>
                ),
                key: '0',
            },
            {
                label: (
                    <Link to='/'>
                        Settings
                    </Link>
                ),
                key: '1',
            },
            {
                type: 'divider',
            },
            {
                label: (
                    <Link to='/logout'>
                        Logout
                    </Link>
                ),
                key: '2',
            },
            // {
            //     label: 'Logout',
            //     key: '3',
            //     disabled: true,
            // },
        ]}
    />
)

export default AppHeader
