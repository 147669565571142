import React, { useEffect, useState } from 'react'
import { Col, Row, Layout } from 'antd';
import LoginPage from './LoginPage';
import LoginSlider from './LoginSlider';

const LoginContainer = () => {
    const width = useCurrentWidth()
    return (
        <Layout className='login-page'>
            {
                width >= 900 && <Row justify='center'>
                    <Col span={6} style={contentStyle} >
                        <LoginPage />
                    </Col>
                    <Col span={18} style={contentStyle} >
                        <LoginSlider />
                    </Col>
                </Row>
            }
            {
                width < 900 && <LoginPage />
            }
        </Layout>
    )
}

const contentStyle = {
    height: '100vh'
}

const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth

const useCurrentWidth = () => {
    let [width, setWidth] = useState(getWidth());
    useEffect(() => {
        const resizeListener = () => {
            setWidth(getWidth())
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])
    return width;
}

export default LoginContainer