import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { accountApi } from "../services/account.service"
import { affiliateApi } from "../services/affiliate.service"

export const store = configureStore({
    reducer: {
        [accountApi.reducerPath]: accountApi.reducer,
        [affiliateApi.reducerPath]: affiliateApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(accountApi.middleware)
            .concat(affiliateApi.middleware),
})

setupListeners(store.dispatch)