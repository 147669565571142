import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from './authBase.service'

export const affiliateApi = createApi({
    reducerPath: 'affiliate',
    baseQuery: baseQueryWithAuth,
    endpoints: (builder) => ({
        getAffiliateInfo: builder.query({
            query: (params) => ({
                url: '/affiliates',
                method: 'GET',
                params
            })
        }),
        getWithdrawal: builder.query({
            query: (params) => ({
                url: `/balance/withdraws`,
                method: 'GET',
                params
            })
        }),
        getBalance: builder.query({
            query: () => ({
                url: `/balance`,
                method: 'GET',
            })
        })
    })
})

export const {
    useGetAffiliateInfoQuery,
    useGetWithdrawalQuery,
    useGetBalanceQuery
} = affiliateApi