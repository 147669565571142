import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from './authBase.service'

export const accountApi = createApi({
    reducerPath: 'account',
    baseQuery: baseQueryWithAuth,
    endpoints: (builder) => ({
        getUserInfo: builder.query({
            query: () => `/accounts/info`,
        }),
    })
})

export const { useGetUserInfoQuery } = accountApi
