import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from './layouts/Layout';
import LoginContainer from './components/login/LoginContainer'
import './styles/style.scss'
import LogoutContainer from './components/logout/LogoutContainer';
import OauthCallBack from './components/oauth-callback/OauthCallBack';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<MainLayout />} />
        <Route path="/login" element={<LoginContainer />} />
        <Route path="/logout" element={<LogoutContainer />} />
        <Route path="/oauth-callback" element={<OauthCallBack />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;