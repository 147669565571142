import React, { useEffect, useState } from 'react'
import * as AuthServices from '../../services/auth.service'
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom'
import { useGetUserInfoQuery } from '../../services/account.service'

const OauthCallBack = () => {
    const navigate = useNavigate()
    const [token, setToken] = useState('')
    const [searchParams] = useSearchParams()
    const { data } = useGetUserInfoQuery(null, { skip: !token })
    useEffect(() => {
        _initSetup()
    })
    const _initSetup = () => {
        const accessToken = searchParams.get('accessToken')
        const refreshToken = searchParams.get('refreshToken')
        const expiresIn = searchParams.get('expiresIn')
        _setup({ accessToken, refreshToken, expiresIn })
    }

    const _setup = ({ accessToken, refreshToken, expiresIn }) => {
        setToken(accessToken)
        AuthServices.setAccessToken(accessToken)
        AuthServices.setRefreshToken(refreshToken)
        if (data && data.success) {
            AuthServices.setUserData(data.data)
            navigate('/')
        }
    }

    return (
        <div className="oauth">
            loading...
        </div>
    )
}

export default OauthCallBack