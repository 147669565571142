import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAccessToken } from './auth.service'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API,
    prepareHeaders: (headers, { getState }) => {
        const token = getAccessToken()
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    }
})

export const baseQueryWithAuth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if ([401, 403].includes(result?.error?.status)) {
        // window.location.href = '/login'
    }
    return result
}
