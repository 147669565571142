import React, { useEffect, useState } from 'react'
import { Carousel } from 'antd';
import Papa from 'papaparse'
import { getLocalData, setLocalData } from '../../services/storage.service'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const LoginSlider = () => {
    const [dataSlide, setDataSlide] = useState({
        timeCheck: process.env.NODE_ENV === 'production' ? 7200 : 30,
        listSlides: getLocalData('dataSlides') && getLocalData('dataSlides').length > 0 ? getLocalData('dataSlides') : [],
    })
    useEffect(() => {
        const timeCheck = process.env.NODE_ENV === 'production' ? 7200 : 30

        if (!(getLocalData('dataSlides') && getLocalData('dataSlides').length > 0) || (parseInt(Math.floor(Date.now() / 1000)) - parseInt(getLocalData('timeUpdateDataSlides')) > parseInt(timeCheck))) {
            Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vRfA9hm2Au6rg-in9ENdzqeLf6oplF1iuzaZHIxSL1vON9L9a0nDEfqkubA8RQiLV0o6SR262dj1nKF/pub?gid=0&single=true&output=csv", {
                download: true,
                header: true,
                complete: (result) => {
                    const { data = [] } = Object.assign({}, result)
                    setSlides(data)
                }
            })
        }
    })

    const setSlides = (data) => {
        setDataSlide({ listSlides: data })
        setLocalData('dataSlides', JSON.stringify(data))
        setLocalData('timeUpdateDataSlides', Math.floor(Date.now() / 1000))
    }
    return (
        <div className='login-slider'>
            <Carousel arrows swipeToSlide draggable {...settings}>
                {
                    dataSlide.listSlides.length > 0 && dataSlide.listSlides.map((slide, index) => (
                        <div className='slide-wrapper' key={index}>
                            <img src={slide.image} alt='' />
                            <div className="slide-content">
                                <h2 className="slide-title">{slide.title}</h2>
                                <div className="slide-description">
                                    {slide.description}
                                </div>
                                <div className="slide-link">
                                    <a target="_blank" rel="noopener noreferrer" href={`${slide.link}?utm_source=sellerdb&utm_medium=login&utm_campaign=inbound`}>{slide.button}</a>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </Carousel>
        </div>

    )
}

const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                color: 'black',
                fontSize: '15px',
                lineHeight: '1.5715'
            }}
            onClick={onClick}
        >
            <RightOutlined />
        </div>
    )
}

const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                color: 'black',
                fontSize: '15px',
                lineHeight: '1.5715'
            }}
            onClick={onClick}
        >
            <LeftOutlined />
        </div>
    )
}


const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
}


export default LoginSlider
